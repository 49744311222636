
import React, { Component } from "react";
import Recaptcha from "react-recaptcha";
//import styled from 'styled-components';
import Layout from "../components/layout";
import Style from "./Contact.module.css";

class App extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);

    this.state = {
      isVerified: false,
      email: '',
      name: '',
      contactTime: '',
      Time: '',
      message: '',


    };
    this.inputSubmit = this.inputSubmit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  recaptchaLoaded() {
    console.log("capcha successfully loaded");
  }

  handleSubmit(e) {
    if (this.state.isVerified) {
      const { name, email, contactTime, Time, message } = this.state;
      let body = `Name=${name} <br> Email=${email} <br> Best Time To Contact=${contactTime} <br> Time Zone=${Time} <br> Meassge=${message}`
      window.open(`mailto:hr@prueba.io?body=${body}`)
    } else {
      e.preventDefault();
      alert("Please verify that you are a human!");
    }
  }
  inputSubmit(e) {
    const data = { [e.target['name']]: e.target.value }
    this.setState(
      data
    );
  }

  verifyCallback(response) {
    if (response) {
      this.setState({
        isVerified: true,
      });
    }
  }
  render() {
    return (
    
      <Layout>
        <div className={Style.div}>
          <h3>Prueba India Offices:</h3>
          <ul>
            <li>Warrandyte, Victoria, AUSTRALIA, 3113</li>
            <li>
              4th Floor, Reliance Trends Building, Udupi-Manipal Road, Manipal,
              Karnataka 576102 - <a href="https://goo.gl/maps/G8Eq9TbT1b5WspZa8">location</a>
            </li>
          </ul>
        </div>
        <form
          onSubmit={this.handleSubmit}
          method="post"
          enctype="text/plain"
        >
          <fieldset>
            
          <div class="row">
      <div class="col-25">
                <label for="name" className={Style.labelHeading} htmlFor="name">
                  Name
                </label>
              </div>
              <div class="col-75">
              <input
                type="text"
                name="name"
                id="name"
                onChange={this.inputSubmit}
                value={this.state.name}
                required
                pattern="[A-Za-z]+"
                aria-required="true"
                aria-describedby="name-format"
              />
              <span id="name-format" className={Style.help}>
                *
              </span>
              </div>
              </div>
              <div class="row">
                <div class="col-25">
                <label for="email" className={Style.labelHeading} id="email" htmlFor="email">
                  E-mail ID
                </label>
                </div>
                <div class="col-75">
              <input
                type="email"
                name="email"
                onChange={this.inputSubmit}
                value={this.state.email}
                id="email"
                required
                aria-required="true"
                aria-describedby="name-format"
              />
              <span id="name-format" className={Style.help}>
                *
              </span>
           </div>
           </div>
           <div class="row">
      <div class="col-25">
              <label htmlFor="contact" className={Style.labelHeading}>Best Time To Contact</label>
              </div>
              <div class="col-75">
              <select onChange={this.inputSubmit} type="text" name="contactTime" required>
                <option value="">None</option>
                <option value="Morning">Morning</option>
                <option value="Afternoon">Afternoon</option>
                <option value="Evening">Evening</option>
              </select>
           </div>
           </div>
           <div class="row">
      <div class="col-25">
              <label htmlFor="time" className={Style.labelHeading}>Time Zone</label>
              </div>
              <div class="col-75">
              <div onChange={this.inputSubmit}>
                <input

                  type="radio"
                  id="pst"
                  name="Time"
                  value="PST"
                  required
                />
                <label htmlFor="PST" for="PST">
                  PST
              </label>
                <br></br>
                <input type="radio" id="est" name="Time" value="EST" />
                <label htmlFor="EST" for="EST">
                  EST
              </label>
                <br></br>
                <input type="radio" id="gST" name="Time" value="GST" />
                <label htmlFor="GST" for="GST">
                  GST
              </label>
                <br></br>
              </div>
            </div>
            </div>
            <div class="row">
      <div class="col-25">
             <label htmlFor="question" className={Style.labelHeading}> Questions / Comments</label>
             </div>
             <div class="col-75">
              <textarea
                onChange={this.inputSubmit}
                name="message"
                id="message"
                rows="5"
                cols="30"
                required
                aria-required="true"
                aria-describedby="name-format"
              />
              <span id="name-format" className={Style.text}>
                *
              </span>
           </div>
           </div>
           <div class="col-25" >
                <input

                  type="checkbox"
                  id="agree-1"
                  name="Agree"
                  value="True"
                  required
                />
                <label htmlFor="agr" for="agree-1">
                  I have read and agree to the
                  <a
                    href="/#"
                    onclick="window.open('/privacy/policy.htm','privacy_policy','fullscreen=no,location=no,menubar=no,scrollbars=yes,status=no,toolbar=no,width=640,height=480');return false;"
                  >
                    Privacy Policy
                  </a>
                </label>
              </div>
              <div class="col-25">
            <Recaptcha
              sitekey="6LfULWcaAAAAAF80Mmo_ohvkcy6cCfzO94hPgIKp"
              render="explicit"
              onloadCallback={this.recaptchaLoaded}
              verifyCallback={this.verifyCallback}
            />
         </div>
         <div class="row">
      <input type="submit" value="Submit" />
      </div>
          </fieldset>
     
        </form>
       
      </Layout>
    );

  }
}

export default App;
